@tailwind base;
@tailwind components;
@tailwind utilities;

.character {
    font-size: 20px;
    border-radius: 8px;
    border-color: #bdbdbd;
    border-width: 1px;
    /* light theme */
    color: #272729;
    background-color: #fff;

    /* dark theme */
    color: #000;
    border-color: #bdbdbd;
    border-width: 1px;
    height: 50px;
    width: 20px;
}