/* Ваш стиль для кастомного скролла */
::-webkit-scrollbar {
    width: 5px; /* Ширина скроллбара */
}

::-webkit-scrollbar-thumb {
    background: #4D89E3; /* Цвет ползунка */
    width: 5px;
    border-radius: 5px; /* Скругление углов ползунка */
}

  /* Добавьте необходимые стили для других браузеров здесь */
